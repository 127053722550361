<template>
  <div class="record-item" :style="{ marginBottom: isLast ? '0' : '.41rem' }">
    <div class="record-item-header">
      <p>
        <img
          :src="
            type == 1
              ? require(`@assets/train_bg.png`)
              : require(`@assets/aircraft_bg.png`)
          "
          alt=""
        />
        <span v-if="item.ticketNo">{{
          type == 2 ? item.flightNo : item.ticketNo
        }}</span>
        <span v-else>{{ type == 2 ? item.flightNo : item.trainNo }}</span>
      </p>
      <p>
        <span>{{ item.dateTime || item.startDate }} </span>
      </p>
      <p>
        <span>{{
          dictsCodeMap["ticket_order_status"][item.status] == "待出票"
            ? "出票中"
            : dictsCodeMap["ticket_order_status"][item.status]
        }}</span>
      </p>
    </div>
    <div class="record-item-time">
      <div class="start">
        <span class="time">{{ item.startTime }}</span>
        <span class="address"
          >{{ item.startStation }}{{ item.startTerminal }}</span
        >
      </div>
      <div class="center">
        <div class="transportation-mode">
          <span></span>
          <span v-if="type">{{ type == 1 ? "火车" : "飞机" }}</span>
          <span></span>
        </div>
        <div class="vote-type">
          {{ item.bookingMethod == 1 ? "在线订票" : "个人添加" }}
        </div>
      </div>
      <div class="end">
        <span class="time">{{ item.endTime }}</span>
        <span class="address">{{ item.endStation }}{{ item.endTerminal }}</span>
        <span class="tips" v-if="item.day && item.day >= 1"
          >+{{ item.day }}天</span
        >
      </div>
    </div>
    <div class="record-item-seat" v-if="item.seatInfo || item.seat">
      <span v-if="type == 1">{{ item.seatInfo }}</span>
      <span v-else>
        {{ item.airline }}{{ item.flightNo || item.ticketNo }}
      </span>
      <span>{{
        dictsCodeMap[type == 1 ? "train_ticket_seat" : "air_ticket_seat"][
          item.seat
        ]
      }}</span>
      <!-- <span v-if="item.punctualRate">{{ item.punctualRate }}%准点率</span> -->
    </div>
    <div class="line"></div>
    <!-- <template v-if="item.bookingMethod == 1">
      <div class="record-item-btn" v-if="status == 20 || status == 40">
        <span @click="handleClick(3)"> 申请退票</span>
        <template v-if="type == 1">
          <span @click="handleClick(6)" v-if="status == 20">申请改签</span>
        </template>
        <template v-else>
          <span @click="handleClick(6)">申请改签</span>
        </template>
      </div>
      <div class="record-item-btn" v-if="status == 10">
        <span @click="handleClick(2)">取消出票</span>
      </div>
      <div class="record-item-btn" v-if="status == 60">
        <span @click="handleClick(5)">取消退票</span>
      </div>
      <div class="record-item-btn" v-if="status == 30">
        <span @click="handleClick(4)">取消改签</span>
      </div>
    </template> -->
    <!-- <div
      class="record-item-btn"
      v-if="item.bookingMethod == 2 && !(status == 90 || status == 50)"
    >
      <span @click="handleClick(1)">删除</span>
    </div> -->
  </div>
</template>

<script>
import OperationDialog from './OperationDialog.vue'
export default {
  components: { OperationDialog },
  props: {
    type: {},
    index: {},
    status: {
      type: String,
      dafault: 0
    },
    item: {
      type: Object,
      dafault: {}
    },
    isLast: {},
    dictsCodeMap: {}
  },
  data () {
    return {
      isOperation: false
    }
  },
  methods: {

    handleClick (type) {
      let text = ''
      switch (type) {
        case 3:
          text = '申请退票';
          break
        case 6:
          text = '申请改签';
          break
        case 2:
          text = '取消出票';
          break
        case 5:
          text = '取消退票';
          break
        case 4:
          text = '取消改签';
          break
        case 1:
          text = '删除';
          break
      }
      this.$dialog.confirm({
        message: '您确认' + text + '?',
        confirmButtonText: '取消',
        cancelButtonText: text,
        cancelButtonColor: '#323233',
        confirmButtonColor: '#0089FF'
      }).then(() => {
        console.log('点击取消')
      }).catch(() => {
        console.log(text, this.item)

        this.$emit('handleStatus', {
          type: type, index: this.index, ticketsId: this.item.id, start: this.item.startStation,
          end: this.item.endStation,
          startCity: this.item.startCity,
          endCity: this.item.endCity,
          transportation: this.type,
          date: this.item.startDate
        })


      });
    }
  },
  handleOperation (data) {
    console.log(data, '操作人信息')
  }
}
</script>

<style lang="scss" scoped>
.record-item {
  padding: 0.41rem 0.57rem 0 0.57rem;
  width: 100%;
  background: #fff;
  border-radius: 0.41rem;
  margin-bottom: 0.41rem;
  .record-item-header,
  .record-item-time,
  .record-item-seat,
  .record-item-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .record-item-header {
    > p:nth-child(1) {
      display: flex;
      align-items: center;
      width: 4rem;
      > img {
        margin-right: 0.16rem;
        width: 0.74rem;
        height: 0.74rem;
      }
      > span {
        font-size: 0.57rem;
        font-weight: 500;
        color: #162b40;
      }
    }
    > p:nth-child(3) {
      width: 4rem;
      text-align: right;
    }
    > p:nth-child(2) span {
      font-size: 0.57rem;
      color: #162b40;
    }
    > p:nth-child(3) span {
      font-size: 0.57rem;
      color: #0072f5;
    }
  }
  .record-item-time {
    cursor: pointer;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    margin-bottom: 0.41rem;
    margin-top: 0.64rem;
    .start,
    .end {
      width: 4rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .time {
        font-size: 0.98rem;
        font-weight: 600;
        color: #162b40;
      }
      .address {
        margin-top: 0.28rem;
        font-size: 0.49rem;
        color: rgba(22, 43, 64, 0.85);
      }
      .tips {
        position: absolute;
        top: -0.5rem;
        right: 0;
        font-size: 0.45rem;
        color: rgba(22, 43, 64, 0.85);
      }
    }
    .start {
      align-items: flex-start;
    }
    .end {
      align-items: flex-end;
    }
    .center {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .transportation-mode {
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          display: flex;
        }
        span:nth-child(1) {
          width: 0.74rem;
          height: 0.04rem;
          background: linear-gradient(
            117deg,
            rgba(0, 114, 245, 0),
            rgba(1, 114, 245, 1)
          );
        }
        span:nth-child(2) {
          align-items: center;
          justify-content: center;
          width: 2.54rem;
          height: 0.94rem;
          background: rgba(0, 114, 245, 0.15);
          border-radius: 0.69rem;
          font-size: 0.49rem;
          font-weight: 500;
          color: #0072f5;
          border: 0.04rem solid #0072f5;
        }
        span:nth-child(3) {
          width: 0.74rem;
          height: 0.04rem;
          background: linear-gradient(
            243deg,
            rgba(0, 114, 245, 0),
            rgba(0, 114, 245, 1)
          );
        }
      }
      .vote-type {
        margin-top: 0.21rem;
        font-size: 0.49rem;
        color: rgba(34, 38, 37, 0.65);
      }
    }
  }
  .record-item-seat {
    width: 100%;
    height: 1.64rem;
    background: #eff2f9;
    border-radius: 0.33rem;
    padding: 0 0.66rem;
    margin: 0.66rem 0;
    span:nth-child(1) {
      font-size: 0.57rem;
      font-weight: 500;
      color: #162b40;
    }
    span:nth-child(2) {
      font-size: 0.57rem;
      color: rgba(22, 43, 64, 0.65);
    }
  }
  .line {
    width: 12.31rem;
    height: 0.02rem;
    margin: 0 auto;
    background: #ececec;
  }
  .record-item-btn {
    cursor: pointer;
    height: 1.64rem;
    justify-content: center;
    > span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-size: 0.57rem;
      color: rgba(22, 43, 64, 0.45);
    }
  }
}
::v-deep {
  .van-action-sheet__header {
    text-align: left;
    padding-left: 0.66rem;
    font-weight: 500;
    font-size: 0.66rem;
    color: #323233;
  }
}
</style>