<template>
  <div class="incoming-booking">
    <div style="height: 0.41rem"></div>
    <Headbar
      @add="$emit('add')"
      :backgroundColor="'linear-gradient(270deg, #A2F3CA 0%, #22CC77 100%)'"
      :text="'来程订票'"
      :isAdd="state == 1 && status != 10 ? true : false"
    ></Headbar>
    <div class="warp" v-if="state == 1">
      <template v-if="!(status == 10 || status == 20)">
        <list-item
          v-for="(item, index) in tickets"
          :key="index"
          :index="index"
          :isLast="index == tickets.length - 1"
          :item="item.order"
          :type="item.trafficType"
          :status="item.status"
          :dictsCodeMap="dictsCodeMap"
          @handleStatus="handleStatus"
        ></list-item>
      </template>
      <noData
        v-if="status == 10"
        :icon="require('@assets/self.png')"
        :title="'无需订票'"
        :text="'您已设置为无需订票，如需在线订票或添加个人已定票，请联系您的专属秘书进行更改'"
      >
      </noData>
      <div class="warp-booking" v-if="status == 20">
        <noData :icon="require('@assets/booing_no_data.png')"> </noData>
        <div class="footer">
          <!-- <span @click="addSlefTickes">添加个人订票</span>
          <span @click="onlineTickes">在线订票</span> -->
        </div>
      </div>
    </div>
    <div class="warp" v-else>
      <noData
        :icon="require('@assets/self.png')"
        :title="'无需订票'"
        :text="'您已设置为无需订票，如需在线订票或添加个人已定票，请联系您的专属秘书进行更改'"
      >
      </noData>
    </div>
  </div>
</template>

<script>
import Headbar from './Headbar.vue'
import ListItem from '@components/Common/ListItem.vue'
import noData from '@components/Common/NoData.vue'
export default {
  components: {
    Headbar,
    ListItem,
    noData
  },
  props: {
    tickets: {
      type: Object,
      default: []
    },
    state: {
      type: String,
      default: 0
    },
    status: {
      type: String,
      default: 0
    },
    dictsCodeMap: {}
  },
  methods: {
    addSlefTickes () {
      this.$emit('addSlefTickes')
    },
    onlineTickes () {
      this.$emit('onlineTickes')
    },
    handleStatus (data) {
      this.$emit('handletTicket', { ...data, id: this.tickets[data.index].guestTicketId, iscoming: 1 })
    }
  }
}
</script>

<style lang="scss" scoped>
.incoming-booking {
  .warp {
    margin-top: -1rem;
    border-radius: 0.41rem;
    // background: #fff;
    ::v-deep {
      .record-item {
        padding: 0.41rem 0.29rem 0 0.29rem;
        .line {
          width: 10.26rem;
          height: 0.02rem;
          margin: 0 auto;
          background: #ececec;
        }
      }
      .no-data {
        background: #fff;
        padding: 0.21rem 0;
        margin-top: 0.1rem;
        overflow: hidden;
        > img {
          border-radius: 0.41rem;
          width: 100%;
        }
      }
    }
    .warp-booking {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: 0 0.33rem;
      padding-bottom: 1.23rem;
      background: #fff;
      border-radius: 0.41rem;
      cursor: pointer;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .add {
        width: 5.21rem;
        height: 1.81rem;
        border-radius: 0.21rem;
        border: 0.02rem solid #dcdee0;
        font-size: 0.57rem;
        color: #162b40;
      }
      .booking {
        width: 5.21rem;
        height: 1.81rem;
        background: #0072f5;
        box-shadow: 0rem 0.25rem 0.49rem 0rem rgba(0, 114, 245, 0.15);
        border-radius: 0.21rem;
        font-size: 0.57rem;
        color: #ffffff;
      }
      .footer {
        margin-top: 0.61rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.57rem;
          color: #162b40;
        }
        span:nth-child(1) {
          width: 5.21rem;
          height: 1.81rem;
          border-radius: 0.21rem;
          border: 0.02rem solid #dcdee0;
        }
        span:nth-child(2) {
          width: 5.35rem;
          height: 1.81rem;
          background: #0072f5;
          box-shadow: 0rem 0.21rem 0.39rem 0rem rgba(0, 114, 245, 0.15);
          border-radius: 0.26rem;
          color: #fff;
        }
      }
    }
  }
}
</style>