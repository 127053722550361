<template>
  <div class="schedule" v-if="sessions.length">
    <div style="height: 0.41rem"></div>
    <Headbar
      :backgroundColor="'linear-gradient(270deg, #FFE5C4 0%, #D49B53 100%)'"
      :text="'我的日程'"
    ></Headbar>
    <div class="warp" v-for="(item, index) in sessions" :key="index">
      <div class="warp-bg">
        <div class="schedule-date">
          {{ getDay(item.time) }}
        </div>
        <div
          v-for="(citem, cindex) in item.session_info"
          :key="cindex"
          class="warp-content"
        >
          <div class="warp-navbar">
            <span class="icon"></span>
            <span class="time-slot"> {{ citem.speech_time }} </span>
            <span class="reputation">{{ citem.role }}</span>
          </div>
          <div class="warp-title van-multi-ellipsis--l2">
            {{ citem.title }}
          </div>
          <div class="line"></div>
          <div class="warp-cell" v-if="citem.offline_location">
            <img src="@assets/address.png" alt="" />
            <span>{{ citem.offline_location }}</span>
          </div>
          <div class="warp-cell" v-if="citem.session_title">
            <img src="@assets/discuss.png" alt="" />
            <span>{{ citem.session_title }}</span>
          </div>
          <div class="warp-cell" v-if="citem.speech_title">
            <img src="@assets/speech.png" alt="" />
            <span>{{ citem.speech_title }}</span>
          </div>
          <!-- <div class="line" v-if="cindex != item.session_info.length - 1"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headbar from './Headbar.vue'
import noData from '@components/Common/NoData.vue'
export default {
  components: {
    Headbar,
    noData
  },
  props: {
    sessions: {
      type: Array,
      default: []
    }
  },
  methods: {
    // 获取日期
    getDay(value) {
      let date = new Date(value)
      return (date.getYear() + 1900) + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日"
    },
  }
}
</script>

<style lang="scss" scoped>
.schedule {
  width: 100%;
  .warp {
    margin-bottom: 0.41rem;
  }
  .warp-bg {
    margin-top: -1rem;
    background: #fff;
    padding-top: 0.41rem;
    border-radius: 0.41rem;
  }
  .schedule-date {
    width: 5.37rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 0.15rem;

    padding: 0.16rem 0.49rem;
    background: rgba(255, 206, 146, 0.25);
    border-radius: 0.57rem;
    font-weight: 500;
    font-size: 0.57rem;
    color: #8d400c;
  }
  .warp-content {
    background: #fff;
    padding: 0.49rem 0.29rem;
    border-radius: 0.41rem;
    // margin-bottom: 1.41rem;
    border-bottom: 1px solid #ececec;

    .warp-navbar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 0.92rem;
      .icon {
        position: absolute;
        left: -0.33rem;
        top: 0.21rem;
        width: 0.16rem;
        height: 0.49rem;
        background: #d49b53;
        border-radius: 0rem 0.08rem 0.08rem 0rem;
      }
      .time-slot {
        font-size: 0.66rem;
        font-weight: 600;
        color: #162b40;
      }
      .reputation {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.21rem;
        height: 0.72rem;
        background: linear-gradient(135deg, #62523d 0%, #322616 100%);
        border-radius: 0.16rem;
        border: 0.02rem solid;
        border-image: linear-gradient(
            135deg,
            rgba(255, 244, 216, 1),
            rgba(189, 175, 142, 0.25)
          )
          0.02 0.02;
        font-size: 0.49rem;
        color: #f9d3ae;
      }
    }
    .warp-title {
      margin-top: 0.62rem;
    }
    .line {
      //   width: 10.64rem;
      height: 0.02rem;
      margin: 0.39rem auto 0.49rem 0;
      background: #ececec;
    }
    .warp-cell {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.39rem;
      > img {
        margin-top: 0.1rem;
        width: 0.57rem;
        height: 0.57rem;
      }
      span {
        margin-left: 0.33rem;
        font-size: 0.53rem;
        color: rgba(22, 43, 64, 0.85);
      }
    }
  }
  .warp-content:last-child {
    margin-bottom: 0;
  }
  .warp:last-child {
    margin-bottom: 0;
  }
}
</style>